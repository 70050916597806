<template>
  <div class="content">
    <PageHeader :title="$t('general-settings.header')" />
    <div class="page-content container-fluid settings">
      <div class="row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <!-- Notificações -->
              <div class="notificatios-grid">
                <b class="title">{{ $tc('generic-str.notification', 2) }}</b>
                <ul class="list-unstyled">
                  <li>
                    <div class="control-group opt-2 toggle-check">
                      <label class="control control-checkbox">
                        <input type="checkbox" name="notifications-opt" />
                        <div class="control_indicator"></div>
                        <span>{{ $t('properties.desktop-notification') }}</span>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="control-group opt-2 toggle-check">
                      <label class="control control-checkbox">
                        <input type="checkbox" name="notifications-opt" />
                        <div class="control_indicator"></div>
                        <span>{{ $t('properties.email-notification') }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <!--/Notificações -->
              <!-- Preferências -->
              <div class="prefs-grid">
                <b class="title">{{ $t('billing.prefs.header') }}</b>
                <div class="lang_wrapper">
                  <p>{{ $t('properties.language-preferences') }}</p>
                  <OptLang />
                </div>
                <div class="gmt_wrapper">
                  <p>{{ $t('properties.timezone') }}</p>
                  <GmtOpt />
                </div>
              </div>
              <!--/Preferências -->
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <Darkmode />
        </div> -->
        <div class="col-lg-3">
          <div class="card">
            <div class="card-body plans-card">
              <div class="plan-desc">
                <b class="title">{{ $t('your_plan') }}</b>
                <span class="my-plan">Plus</span>
                <p class="plan-value">
                  R$<span>1.189,00</span>/mês<br /><small>R$ 588/ano</small>
                </p>
                <p>
                  {{ $t('properties.plan-info.omni') }}
                </p>
              </div>
              <div class="plan-info">
                <span>{{ $t('properties.infos') }}</span>
                <p><b>{{$t('app.fee')}}</b><span>R$ 88 /mês</span></p>
                <p><b>{{$tc('app.message', 2)}}</b><span>R$ 88 /mês</span></p>
                <p><b>{{$tc('app.agent', 2)}}</b><span>R$ 88 /mês</span></p>
                <p><b>{{$t('app.storage')}}</b><span>R$ 88 /mês</span></p>
              </div>
              <router-link to="/billing/upgrade" class="btn btn-yup-purple">
                {{ $t('properties.btn-change-plan') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row"> -->
        <!-- <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">{{ $t('properties.title') }}</h5>
            <div class="card-body">
              <form action="#" class="form-horizontal">
                <div class="form-body">
                  <div class="form-group row">
                    <label
                      class="control-label text-left col-md-3 weight-500"
                      >{{ $t('properties.company') }}</label
                    >
                    <div class="col-md-5">
                      <input
                        type="text"
                        :value="form.name"
                        class="form-control"
                        autocomplete="given-name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-left col-md-3 weight-500"
                      >ID</label
                    >
                    <div class="col-md-9">{{ account.id }}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">{{ $t('live-credentials.title') }}</h5>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <label class="weight-500">ID</label>
                  <div>{{ account.id }}</div>
                </div>
                <div class="form-group">
                  <label class="weight-500">AUTH TOKEN</label>
                  <HiddenInput
                    :value="account.auth_token"
                    :readonly="true"
                    title="Token de autenticação"
                  />
                </div>
              </form>
            </div>
          </div>
        </div> -->
        <!--
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">{{$t('msg-optout-rcs-wpp.title')}}</h5>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <div>
                    <textarea class="form-control" v-model="optoutMessage"></textarea>
                  </div>
                  <br>
                  <div>
                    <button class="btn btn-success" type="button" @click="saveMessage" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }">{{$t('generic-str.save')}}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
import WhatsService from '@/services/whatsapp.service';
import OptLang from '@/components/rebranding/OptLang.vue';
import GmtOpt from '@/components/rebranding/GmtOpt.vue';
// import Darkmode from '@/components/rebranding/SetTheme.vue';

export default {
  name: 'Settings',
  components: {
    PageHeader,
    // HiddenInput,
    OptLang,
    GmtOpt,
    // Darkmode,
  },
  data() {
    return {
      optoutMessage: '',
      isSending: false,
      form: {
        name: this.$store.state.account.name,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    saveMessage() {
      this.isSending = true;
      WhatsService.optoutsMessage({
        message: this.optoutMessage,
      }).then(
        (response) => {
          console.log(response);
          this.isSending = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('general-settings.toast.saved'),
            type: 'success',
          });
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
  },
  mounted() {
    WhatsService.optoutsSettings().then(
      (response) => {
        console.log(response.data.message);
        this.optoutMessage = response.data.message;
      },
      (error) => {
        this.content = error;
        console.log(JSON.stringify(error.response.data));
      },
    );
  },
};
</script>
<style scoped lang="scss">
.page-content .row > div {
  margin-bottom: 1rem;
}

.page-content .row div .card {
  height: 100%;
}

.settings .card {
  padding-top: 1rem;
}

.lang-opt .card-body {
  display: flex;
  align-items: center;
}

.notificatios-grid,
.prefs-grid,
.themes-opt .card-body,
.plans-card,
.plan-info {
  display: flex;
  flex-direction: column;
}

.notificatios-grid b,
.prefs-grid .title,
.themes-opt .title {
  margin-bottom: 1.25rem;
}

.lang_wrapper,
.gmt_wrapper {
  width: 50%;
  margin-bottom: 1.75rem;
}

.lang_wrapper p {
  margin-bottom: 0.5rem;
}

.plans-card .title {
  font-size: 1.65rem;
  font-weight: 400;
  color: var(--foreground);
}

.my-plan {
  display: block;
  width: 100%;
  text-align: center;
}

.plan-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-info {
  gap: 2rem;
}

.plan-info p {
  display: flex;
}

.plan-info p span {
  margin-left: auto;
}

.plans-card a {
  width: fit-content;
  margin: auto auto 0 auto;
}

.plan-value span {
  font-size: 1.75rem;
  font-weight: bold;
  position: relative;
  top: 18px;
}

.plan-value small {
  margin-left: 20px;
  position: relative;
  top: 10px;
}

.plan-value ~ p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.plan-info > span:first-of-type {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: -0.5rem;
}
</style>
