var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container p-0",attrs:{"id":"gmt-opt"}},[_c('div',{style:('display: flex; flexWrap: wrap; gap: .7rem')},[_c('b-dropdown',{style:('width: 100%'),attrs:{"id":"dropdown-2","text":_vm.$t('options-div.select-lang'),"variant":"transparent"},model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}},_vm._l((_vm.$i18n.availableLocales),function(locale){return _c('b-dropdown-item',{key:("locale-" + locale),attrs:{"value":locale},on:{"click":function($event){_vm.$i18n.locale = locale;
          _vm.persist();}}},[(locale === 'pt')?_c('div',{style:({
            display: ['-webkit-box', '-ms-flexbox', 'flex'],
            justifyContent: 'space-between',
          })},[_vm._v(" "+_vm._s(_vm.$t('options-div.portuguese'))+" "),_c('img',{class:'flag',attrs:{"src":"/assets/img/flags/brasil.svg","alt":"Brasil"}})]):(locale === 'en')?_c('div',{style:({
            display: ['-webkit-box', '-ms-flexbox', 'flex'],
            justifyContent: 'space-between',
          })},[_vm._v(" "+_vm._s(_vm.$t('options-div.english'))+" "),_c('img',{class:'flag',attrs:{"src":"/assets/img/flags/eua.svg","alt":"EUA"}})]):_c('div',{style:({
            display: ['-webkit-box', '-ms-flexbox', 'flex'],
            justifyContent: 'space-between',
          })},[_vm._v(" "+_vm._s(_vm.$t('options-div.spanish'))+" "),_c('img',{class:'flag',attrs:{"src":"/assets/img/flags/espanha.svg","alt":"Espanha"}})])])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }