<template>
  <section id="lang-opt" class="container p-0">
    <!--<h3>radio buttons</h3>-->
    <!--<input class="mr-2" type="radio" v-model="$i18n.locale" :value="'pt'" />PT
    <div class="w-100"></div>
    <input class="mr-2" type="radio" v-model="$i18n.locale" :value="'en'" />EN
    <div class="w-100"></div>
    <input class="mr-2" type="radio" v-model="$i18n.locale" :value="'es'" />ES-->
    <!--<span>value: {{ $i18n.locale }}</span>-->
    <div :style="'display: flex; flexWrap: wrap; gap: .7rem'">
      <b-dropdown
        id="dropdown-1"
        :text="$i18n.locale === 'pt' ? 'PT-BR' : $i18n.locale === 'en' ? 'EN' : 'ES'"
        v-model="$i18n.locale"
        variant="transparent"
        :style="'width: 100%'"
      >
        <b-dropdown-item
          v-for="locale in $i18n.availableLocales"
          :key="`locale-${locale}`"
          :value="locale"
          @click="
            $i18n.locale = locale;
            persist();
          "
        >
          <div
            :style="{
              display: ['-webkit-box', '-ms-flexbox', 'flex'],
              justifyContent: 'space-between',
            }"
            v-if="locale === 'pt'"
          >
            {{ $t('options-div.portuguese') }}
            <img
              :class="'flag'"
              src="/assets/img/flags/brasil.svg"
              alt="Brasil"
            />
          </div>
          <div
            :style="{
              display: ['-webkit-box', '-ms-flexbox', 'flex'],
              justifyContent: 'space-between',
            }"
            v-else-if="locale === 'en'"
          >
            {{ $t('options-div.english') }}
            <img :class="'flag'" src="/assets/img/flags/eua.svg" alt="EUA" />
          </div>
          <div
            :style="{
              display: ['-webkit-box', '-ms-flexbox', 'flex'],
              justifyContent: 'space-between',
            }"
            v-else
          >
            {{ $t('options-div.spanish') }}
            <img
              :class="'flag'"
              src="/assets/img/flags/espanha.svg"
              alt="Espanha"
            />
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <!-- <div class="w-100"></div> -->
      <!-- <button class="btn btn-success ml-3" @click="persist">
        {{ $t('options-div.save') }}
      </button> -->
    </div>
  </section>
</template>
<script>
export default {
  name: 'OptLang',
  type: String,
  data: () => ({
    locale: '',
  }),
  mounted() {
    if (localStorage.locale) {
      this.locale = localStorage.locale;
    }
  },
  methods: {
    persist() {
      localStorage.setItem('OptLang', this.$i18n.locale);
      this.$toast.show({
        title: this.$t('generic-str.success'),
        content: this.$t('options-div.lang-updated'),
        type: 'success',
      });
      document.body.classList.remove('en'); document.body.classList.remove('es'); document.body.classList.remove('pt');
      document.body.classList.add(localStorage.OptLang);
      console.log(this.$i18n.locale);
    },
    setLocale() {
      this.$i18n.global.locale = 'en';
    },
  },
};
</script>
<style lang="scss">
.flag {
  width: 28px;
  height: 24px;
  border-radius: 50%;
  position: relative;
}

#dropdown-1 {
  .dropdown-toggle {
    display: flex !important;
    align-items: center;
    text-align: start;
    font-weight: 400;
    background-color: var(--form-input) !important;
    border: 1px solid !important;
    border-color: var(--form-border) !important;
    color: #839bb3 !important;
  }
  .dropdown-toggle::after {
    margin-left: auto;
  }
}
#lang-opt .dropdown-menu {
  transform: none !important;
}

#lang-opt .dropdown .dropdown-menu {
  width: 100%;
}
</style>
